// COPIED FROM the backend globalModuleSettings file
// used only for limit scale start/end min/max on DeviceMeasurementsForm page
import { MachineType, MachineVersion, Module } from '../graphql/codegen/graphql-react';

interface DefaultModuleSettingsValue {
  scaleStart: number;
  scaleEnd: number;
}

export interface GlobalModuleSetting {
  type: MachineType;
  version: MachineVersion;
  defaultValues: DefaultModuleSettingsValue[];
}

export const getGlobalModuleSetting = (module: Module): GlobalModuleSetting | null => {
  if (!module) {
    return null;
  }
  const globalModuleSetting = globalModuleSettings.find(
    (el) => el?.type === module.type && el?.version === module.modelVersion,
  );
  return globalModuleSetting || null;
};

export const globalModuleSettings: Partial<GlobalModuleSetting[]> = [
  {
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 2,
      },
    ],
  },
  {
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 1000,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCacTc,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 1000,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evo_4CanaliTc,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 1000,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Mco14Evo,
    version: MachineVersion.Mco14_3SezCl2,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 1000,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Mco14Evo,
    version: MachineVersion.Mco14_5SezCl2ClTClC,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 1000,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 2,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 180,
      },
    ],
  },
  {
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161Ma,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 200,
      },
      {
        scaleStart: 0,
        scaleEnd: 180,
      },
    ],
  },
  {
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp162PHRx,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 180,
      },
    ],
  },
  {
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp163ACle11_12,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 180,
      },
    ],
  },
  {
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp163BCle16,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 180,
      },
    ],
  },
  {
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 1000,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Ef315,
    version: MachineVersion.PHClTc,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Ef315,
    version: MachineVersion.PHCacTc,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
  {
    type: MachineType.Evolution,
    version: MachineVersion.Ew2Pool,
    defaultValues: [
      {
        scaleStart: 0,
        scaleEnd: 14,
      },
      {
        scaleStart: 0,
        scaleEnd: 5,
      },
      {
        scaleStart: 0,
        scaleEnd: 100,
      },
    ],
  },
];
