import clone from 'just-clone';
import { MachineType, MachineVersion, Maybe, Module } from '../graphql/codegen/graphql-react';
import { ParametersType } from '../graphql/dataTypes';
import { ls } from '../i18n/translations';
import { capitalize, isNotNullAndNotUndefined } from './utils';

export interface Parameter {
  idx: string;
  name: string;
  type: MachineType;
  version: MachineVersion;
  min: number;
  max: number;
  translationKey: string;
  selectValues?: string[];
  hideMinMax?: boolean;
  selectValuesOffset?: number;
  disabled?: boolean;
  isFavorite?: boolean;
}

let cycleauFirstParameters: Parameter[] = [
  {
    name: 'setpointPH',
    idx: 'P01',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 6.5,
    max: 8.5,
    translationKey: 'setpointPHParamDesc',
  },
  {
    name: 'setpointCl2',
    idx: 'P02',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0.1,
    max: 10,
    translationKey: 'setpointCl2ParamDesc',
  },
  {
    name: 'minPHAlarm',
    idx: 'P03',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 14,
    translationKey: 'minPHAlarmParamDesc',
  },
  {
    name: 'maxPHAlarm',
    idx: 'P04',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 14,
    translationKey: 'maxPHAlarmParamDesc',
  },
  {
    name: 'minFreeChlorine',
    idx: 'P05',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 10,
    translationKey: 'minFreeChlorineParamDesc',
  },
  {
    name: 'maxFreeChlorine',
    idx: 'P06',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 10,
    translationKey: 'maxFreeChlorineParamDesc',
  },
  {
    name: 'settingTypePH',
    idx: 'P07',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypePHParamDesc',
  },
  {
    name: 'settingTypeCl2',
    idx: 'P08',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypeCl2ParamDesc',
  },
  {
    name: 'hysteresisPH',
    idx: 'P09',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0.05,
    max: 14,
    translationKey: 'hysteresisPHParamDesc',
  },
  {
    name: 'hysteresisCl2',
    idx: 'P10',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0.05,
    max: 10,
    translationKey: 'hysteresisCl2ParamDesc',
  },
  {
    name: 'sensePH',
    idx: 'P11',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acid), capitalize(ls.alkaline)],
    translationKey: 'sensePHParamDesc',
  },
  {
    name: 'startDelay',
    idx: 'P12',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 5,
    max: 20 * 60,
    translationKey: 'startDelayParamDesc',
  },
  {
    name: 'flowDelay',
    idx: 'P13',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 5,
    max: 20 * 60,
    translationKey: 'flowDelayParamDesc',
  },
  {
    name: 'maxInjectionTime',
    idx: 'P14',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 24 * 60,
    translationKey: 'maxInjectionTimeParamDesc',
  },
  {
    name: 'pHTankLvl',
    idx: 'P15',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'pHTankLvlParamDesc',
  },
  {
    name: 'chlorineTankLvl',
    idx: 'P16',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'chlorineTankLvlParamDesc',
  },
  {
    name: 'flowSensor',
    idx: 'P17',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'flowSensorParamDesc',
  },
  {
    name: 'language',
    idx: 'P18',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 3,
    selectValues: [
      capitalize(ls.italian),
      capitalize(ls.english),
      capitalize(ls.french),
      capitalize(ls.spanish),
    ],
    translationKey: 'languageParamDesc',
  },
  {
    name: 'alarmRelay',
    idx: 'P19',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'alarmRelayParamDesc',
  },
  {
    name: 'serialLine',
    idx: 'P20',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.ascii), capitalize(ls.modbus)],
    disabled: true,
    translationKey: 'serialLineParamDesc',
  },
  {
    name: 'serialLineCommSpeed',
    idx: 'P21',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 2,
    selectValues: ['9600 BPS', '19200 BPS', '38400 BPS'],
    disabled: true,
    translationKey: 'serialLineCommSpeedParamDesc',
  },
  {
    name: 'modbusCommAddr',
    idx: 'P22',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 1,
    max: 126,
    disabled: true,
    translationKey: 'modbusCommAddrParamDesc',
  },
  {
    name: 'password',
    idx: 'P23',
    type: MachineType.Cycleau,
    version: MachineVersion.First,
    min: 0,
    max: 999,
    disabled: true,
    translationKey: 'passwordParamDesc',
  },
];
cycleauFirstParameters = cycleauFirstParameters.map((el) => ({ ...el, isFavorite: true }));
let cycleauProParameters: Parameter[] = [
  {
    name: 'setpointPH',
    idx: 'P01',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 6.5,
    max: 8.5,
    translationKey: 'setpointPHParamDesc',
  },
  {
    name: 'setpointCl2',
    idx: 'P02',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0.1,
    max: 10,
    translationKey: 'setpointCl2ParamDesc',
  },
  {
    name: 'minPHAlarm',
    idx: 'P03',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 14,
    translationKey: 'minPHAlarmParamDesc',
  },
  {
    name: 'maxPHAlarm',
    idx: 'P04',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 14,
    translationKey: 'maxPHAlarmParamDesc',
  },
  {
    name: 'minFreeChlorine',
    idx: 'P05',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 10,
    translationKey: 'minFreeChlorineParamDesc',
  },
  {
    name: 'maxFreeChlorine',
    idx: 'P06',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 10,
    translationKey: 'maxFreeChlorineParamDesc',
  },
  {
    name: 'maxCombChlorine',
    idx: 'P07',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    translationKey: 'maxCombChlorineParamDesc',
  },
  {
    name: 'settingTypePH',
    idx: 'P08',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypePHParamDesc',
  },
  {
    name: 'settingTypeCl2',
    idx: 'P09',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [ls.onOff.toUpperCase(), capitalize(ls.proportional)],
    translationKey: 'settingTypeCl2ParamDesc',
  },
  {
    name: 'hysteresisPH',
    idx: 'P10',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0.05,
    max: 14,
    translationKey: 'hysteresisPHParamDesc',
  },
  {
    name: 'hysteresisCl2',
    idx: 'P11',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0.05,
    max: 10,
    translationKey: 'hysteresisCl2ParamDesc',
  },
  {
    name: 'sensePH',
    idx: 'P12',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.acid), capitalize(ls.alkaline)],
    translationKey: 'sensePHParamDesc',
  },
  {
    name: 'startDelay',
    idx: 'P13',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 5,
    max: 20 * 60,
    translationKey: 'startDelayParamDesc',
  },
  {
    name: 'flowDelay',
    idx: 'P14',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 5,
    max: 20 * 60,
    translationKey: 'flowDelayParamDesc',
  },
  {
    name: 'maxInjectionTime',
    idx: 'P15',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 24 * 60,
    translationKey: 'maxInjectionTimeParamDesc',
  },
  {
    name: 'pHTankLvl',
    idx: 'P16',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'pHTankLvlParamDesc',
  },
  {
    name: 'chlorineTankLvl',
    idx: 'P17',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'chlorineTankLvlParamDesc',
  },
  {
    name: 'flowSensor',
    idx: 'P18',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'flowSensorParamDesc',
  },
  {
    name: 'totalChlorine',
    idx: 'P19',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'totalChlorineParamDesc',
  },
  {
    name: 'language',
    idx: 'P20',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 3,
    selectValues: [
      capitalize(ls.italian),
      capitalize(ls.english),
      capitalize(ls.french),
      capitalize(ls.spanish),
    ],
    translationKey: 'languageParamDesc',
  },
  {
    name: 'alarmRelay',
    idx: 'P21',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.active), capitalize(ls.deactivated)],
    translationKey: 'alarmRelayParamDesc',
  },
  {
    name: 'serialLine',
    idx: 'P22',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.ascii), capitalize(ls.modbus)],
    disabled: true,
    translationKey: 'serialLineParamDesc',
  },
  {
    name: 'serialLineCommSpeed',
    idx: 'P23',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 2,
    selectValues: ['9600 BPS', '19200 BPS', '38400 BPS'],
    disabled: true,
    translationKey: 'serialLineCommSpeedParamDesc',
  },
  {
    name: 'modbusCommAddr',
    idx: 'P24',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 1,
    max: 126,
    disabled: true,
    translationKey: 'modbusCommAddrParamDesc',
  },
  {
    name: 'password',
    idx: 'P25',
    type: MachineType.Cycleau,
    version: MachineVersion.Pro,
    min: 0,
    max: 999,
    disabled: true,
    translationKey: 'passwordParamDesc',
  },
];
cycleauProParameters = cycleauProParameters.map((el) => ({ ...el, isFavorite: true }));

const Mc14evoPHRxCle12TcParameters: Parameter[] = [
  {
    name: 'P01',
    idx: 'P01',
    translationKey: 'P01MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
    selectValues: [
      'pH',
      'Redox',
      capitalize(ls.notAvailable),
      capitalize(ls.freeChlorine) + ' DPD',
      capitalize(ls.temperature),
      capitalize(ls.totalChlorine) + ' DPD',
    ],
    selectValuesOffset: 1,
  },
  {
    name: 'P02',
    idx: 'P02',
    translationKey: 'P02MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 9,
    selectValues: [
      ls.disabled,
      ls.closingOverthresholds,
      ls.openingOverthreshold,
      'PWM ' + ls.upward,
      'PWM ' + ls.downward,
      ls.alarm + ' NA',
      ls.alarm + ' NC',
      'PFM ' + ls.upward,
      'PFM ' + ls.downward,
      ls.hourlyOperation,
    ],
  },
  {
    name: 'P03',
    idx: 'P03',
    translationKey: 'P03MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: -10,
    max: 20,
  },
  {
    name: 'P04',
    idx: 'P04',
    translationKey: 'P04MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
  },
  {
    name: 'P05',
    idx: 'P05',
    translationKey: 'P05MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P06',
    idx: 'P06',
    translationKey: 'P06MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P07',
    idx: 'P07',
    translationKey: 'P07MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 599,
  },
  {
    name: 'P08',
    idx: 'P08',
    translationKey: 'P08MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 255,
  },
  {
    name: 'P09',
    idx: 'P09',
    translationKey: 'P09MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
    selectValues: [
      'pH',
      'Redox',
      capitalize(ls.notAvailable),
      capitalize(ls.freeChlorine) + ' DPD',
      capitalize(ls.temperature),
      capitalize(ls.totalChlorine) + ' DPD',
    ],
    selectValuesOffset: 1,
  },
  {
    name: 'P10',
    idx: 'P10',
    translationKey: 'P10MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 9,
    selectValues: [
      ls.disabled,
      ls.closingOverthresholds,
      ls.openingOverthreshold,
      'PWM ' + ls.upward,
      'PWM ' + ls.downward,
      ls.alarm + ' NA',
      ls.alarm + ' NC',
      'PFM ' + ls.upward,
      'PFM ' + ls.downward,
      ls.hourlyOperation,
    ],
  },
  {
    name: 'P11',
    idx: 'P11',
    translationKey: 'P11MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: -10,
    max: 20,
  },
  {
    name: 'P12',
    idx: 'P12',
    translationKey: 'P12MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 5,
  },
  {
    name: 'P13',
    idx: 'P13',
    translationKey: 'P13MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P14',
    idx: 'P14',
    translationKey: 'P14MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 1800,
  },
  {
    name: 'P15',
    idx: 'P15',
    translationKey: 'P15MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 599,
  },
  {
    name: 'P16',
    idx: 'P16',
    translationKey: 'P16MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    min: 0,
    max: 255,
  },
  {
    name: 'P17',
    idx: 'P17',
    translationKey: 'P17MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P18',
    idx: 'P18',
    translationKey: 'P18MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P19',
    idx: 'P19',
    translationKey: 'P19MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P20',
    idx: 'P20',
    translationKey: 'P20MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P21',
    idx: 'P21',
    translationKey: 'P21MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P22',
    idx: 'P22',
    translationKey: 'P22MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P23',
    idx: 'P23',
    translationKey: 'P23MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P24',
    idx: 'P24',
    translationKey: 'P24MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P25',
    idx: 'P25',
    translationKey: 'P25MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P26',
    idx: 'P26',
    translationKey: 'P26MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P27',
    idx: 'P27',
    translationKey: 'P27MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P28',
    idx: 'P28',
    translationKey: 'P28MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P29',
    idx: 'P29',
    translationKey: 'P29MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P30',
    idx: 'P30',
    translationKey: 'P30MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P31',
    idx: 'P31',
    translationKey: 'P31MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P32',
    idx: 'P32',
    translationKey: 'P32MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P33',
    idx: 'P33',
    translationKey: 'P33MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P34',
    idx: 'P34',
    translationKey: 'P34MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P35',
    idx: 'P35',
    translationKey: 'P35MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P36',
    idx: 'P36',
    translationKey: 'P36MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P37',
    idx: 'P37',
    translationKey: 'P37MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P38',
    idx: 'P38',
    translationKey: 'P38MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P39',
    idx: 'P39',
    translationKey: 'P39MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P40',
    idx: 'P40',
    translationKey: 'P40MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P41',
    idx: 'P41',
    translationKey: 'P41MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P42',
    idx: 'P42',
    translationKey: 'P42MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P43',
    idx: 'P43',
    translationKey: 'P43MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P44',
    idx: 'P44',
    translationKey: 'P44MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P45',
    idx: 'P45',
    translationKey: 'P45MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P46',
    idx: 'P46',
    translationKey: 'P46MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P47',
    idx: 'P47',
    translationKey: 'P47MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P48',
    idx: 'P48',
    translationKey: 'P48MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P49',
    idx: 'P49',
    translationKey: 'P49MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P50',
    idx: 'P50',
    translationKey: 'P50MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P51',
    idx: 'P51',
    translationKey: 'P51MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P52',
    idx: 'P52',
    translationKey: 'P52MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P53',
    idx: 'P53',
    translationKey: 'P53MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P54',
    idx: 'P54',
    translationKey: 'P54MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P55',
    idx: 'P55',
    translationKey: 'P55MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P56',
    idx: 'P56',
    translationKey: 'P56MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P57',
    idx: 'P57',
    translationKey: 'P57MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P58',
    idx: 'P58',
    translationKey: 'P58MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P59',
    idx: 'P59',
    translationKey: 'P59MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P60',
    idx: 'P60',
    translationKey: 'P60MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P61',
    idx: 'P61',
    translationKey: 'P61MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P62',
    idx: 'P62',
    translationKey: 'P62MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P63',
    idx: 'P63',
    translationKey: 'P63MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P64',
    idx: 'P64',
    translationKey: 'P64MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P65',
    idx: 'P65',
    translationKey: 'P65MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P66',
    idx: 'P66',
    translationKey: 'P66MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P67',
    idx: 'P67',
    translationKey: 'P67MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P68',
    idx: 'P68',
    translationKey: 'P68MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P69',
    idx: 'P69',
    translationKey: 'P69MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P70',
    idx: 'P70',
    translationKey: 'P70MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P71',
    idx: 'P71',
    translationKey: 'P71MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P72',
    idx: 'P72',
    translationKey: 'P72MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P73',
    idx: 'P73',
    translationKey: 'P73MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P74',
    idx: 'P74',
    translationKey: 'P74MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P75',
    idx: 'P75',
    translationKey: 'P75MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P76',
    idx: 'P76',
    translationKey: 'P76MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P77',
    idx: 'P77',
    translationKey: 'P77MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P78',
    idx: 'P78',
    translationKey: 'P78MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P79',
    idx: 'P79',
    translationKey: 'P79MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P80',
    idx: 'P80',
    translationKey: 'P80MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P81',
    idx: 'P81',
    translationKey: 'P81MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P82',
    idx: 'P82',
    translationKey: 'P82MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P83',
    idx: 'P83',
    translationKey: 'P83MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P84',
    idx: 'P84',
    translationKey: 'P84MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P85',
    idx: 'P85',
    translationKey: 'P85MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P86',
    idx: 'P86',
    translationKey: 'P86MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P87',
    idx: 'P87',
    translationKey: 'P87MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P88',
    idx: 'P88',
    translationKey: 'P88MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P89',
    idx: 'P89',
    translationKey: 'P89MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P90',
    idx: 'P90',
    translationKey: 'P90MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P91',
    idx: 'P91',
    translationKey: 'P91MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P92',
    idx: 'P92',
    translationKey: 'P92MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P93',
    idx: 'P93',
    translationKey: 'P93MC14ParamDesc',
    type: MachineType.Mc14Evo,
    version: MachineVersion.Mc14evoPHRxCle12Tc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  // {
  //   name: 'P94',
  //   idx: 'P94',
  //   translationKey: 'P94MC14ParamDesc',
  //   type: MachineType.Mc14Evo,
  //   version: MachineVersion.Mc14evoPHRxCle12Tc,
  //   max: 65535,
  //   min: -65535,
  //   hideMinMax: true,
  // },
];

const NovaNsp161CMinParameters: Parameter[] = [
  {
    name: 'P01',
    idx: 'P01',
    translationKey: 'P01NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    min: 0,
    max: 1,
    selectValues: [capitalize(ls.italian), capitalize(ls.english)],
  },
  {
    name: 'P02',
    idx: 'P02',
    translationKey: 'P02NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P03',
    idx: 'P03',
    translationKey: 'P03NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P04',
    idx: 'P04',
    translationKey: 'P04NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P05',
    idx: 'P05',
    translationKey: 'P05NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P06',
    idx: 'P06',
    translationKey: 'P06NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P07',
    idx: 'P07',
    translationKey: 'P07NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 180,
    min: 0,
    isFavorite: true,
  },
  {
    name: 'P08',
    idx: 'P08',
    translationKey: 'P08NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P09',
    idx: 'P09',
    translationKey: 'P09NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P10',
    idx: 'P10',
    translationKey: 'P10NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P11',
    idx: 'P11',
    translationKey: 'P11NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P12',
    idx: 'P12',
    translationKey: 'P12NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P13',
    idx: 'P13',
    translationKey: 'P13NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P14',
    idx: 'P14',
    translationKey: 'P14NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P15',
    idx: 'P15',
    translationKey: 'P15NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P16',
    idx: 'P16',
    translationKey: 'P16NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P17',
    idx: 'P17',
    translationKey: 'P17NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P18',
    idx: 'P18',
    translationKey: 'P18NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P19',
    idx: 'P19',
    translationKey: 'P19NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P20',
    idx: 'P20',
    translationKey: 'P20NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P21',
    idx: 'P21',
    translationKey: 'P21NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P22',
    idx: 'P22',
    translationKey: 'P22NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P23',
    idx: 'P23',
    translationKey: 'P23NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P24',
    idx: 'P24',
    translationKey: 'P24NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P25',
    idx: 'P25',
    translationKey: 'P25NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P26',
    idx: 'P26',
    translationKey: 'P26NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P27',
    idx: 'P27',
    translationKey: 'P27NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P28',
    idx: 'P28',
    translationKey: 'P28NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P29',
    idx: 'P29',
    translationKey: 'P29NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P30',
    idx: 'P30',
    translationKey: 'P30NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P31',
    idx: 'P31',
    translationKey: 'P31NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P32',
    idx: 'P32',
    translationKey: 'P32NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P33',
    idx: 'P33',
    translationKey: 'P33NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P34',
    idx: 'P34',
    translationKey: 'P34NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P35',
    idx: 'P35',
    translationKey: 'P35NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P36',
    idx: 'P36',
    translationKey: 'P36NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P37',
    idx: 'P37',
    translationKey: 'P37NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P38',
    idx: 'P38',
    translationKey: 'P38NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P39',
    idx: 'P39',
    translationKey: 'P39NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P40',
    idx: 'P40',
    translationKey: 'P40NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P41',
    idx: 'P41',
    translationKey: 'P41NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P42',
    idx: 'P42',
    translationKey: 'P42NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P43',
    idx: 'P43',
    translationKey: 'P43NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P44',
    idx: 'P44',
    translationKey: 'P44NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P45',
    idx: 'P45',
    translationKey: 'P45NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P46',
    idx: 'P46',
    translationKey: 'P46NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P47',
    idx: 'P47',
    translationKey: 'P47NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P48',
    idx: 'P48',
    translationKey: 'P48NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P49',
    idx: 'P49',
    translationKey: 'P49NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P50',
    idx: 'P50',
    translationKey: 'P50NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P51',
    idx: 'P51',
    translationKey: 'P51NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P52',
    idx: 'P52',
    translationKey: 'P52NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P53',
    idx: 'P53',
    translationKey: 'P53NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P54',
    idx: 'P54',
    translationKey: 'P54NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P55',
    idx: 'P55',
    translationKey: 'P55NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P56',
    idx: 'P56',
    translationKey: 'P56NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P57',
    idx: 'P57',
    translationKey: 'P57NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P58',
    idx: 'P58',
    translationKey: 'P58NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P59',
    idx: 'P59',
    translationKey: 'P59NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P60',
    idx: 'P60',
    translationKey: 'P60NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P61',
    idx: 'P61',
    translationKey: 'P61NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P62',
    idx: 'P62',
    translationKey: 'P62NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P63',
    idx: 'P63',
    translationKey: 'P63NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P64',
    idx: 'P64',
    translationKey: 'P64NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P65',
    idx: 'P65',
    translationKey: 'P65NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P66',
    idx: 'P66',
    translationKey: 'P66NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P67',
    idx: 'P67',
    translationKey: 'P67NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P68',
    idx: 'P68',
    translationKey: 'P68NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P69',
    idx: 'P69',
    translationKey: 'P69NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P70',
    idx: 'P70',
    translationKey: 'P70NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P71',
    idx: 'P71',
    translationKey: 'P71NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P72',
    idx: 'P72',
    translationKey: 'P72NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P73',
    idx: 'P73',
    translationKey: 'P73NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P74',
    idx: 'P74',
    translationKey: 'P74NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P75',
    idx: 'P75',
    translationKey: 'P75NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P76',
    idx: 'P76',
    translationKey: 'P76NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P77',
    idx: 'P77',
    translationKey: 'P77NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P78',
    idx: 'P78',
    translationKey: 'P78NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P79',
    idx: 'P79',
    translationKey: 'P79NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P80',
    idx: 'P80',
    translationKey: 'P80NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P81',
    idx: 'P81',
    translationKey: 'P81NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P82',
    idx: 'P82',
    translationKey: 'P82NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P83',
    idx: 'P83',
    translationKey: 'P83NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P84',
    idx: 'P84',
    translationKey: 'P84NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P85',
    idx: 'P85',
    translationKey: 'P85NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P86',
    idx: 'P86',
    translationKey: 'P86NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P87',
    idx: 'P87',
    translationKey: 'P87NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P88',
    idx: 'P88',
    translationKey: 'P88NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P89',
    idx: 'P89',
    translationKey: 'P89NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P90',
    idx: 'P90',
    translationKey: 'P90NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P91',
    idx: 'P91',
    translationKey: 'P91NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P92',
    idx: 'P92',
    translationKey: 'P92NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P93',
    idx: 'P93',
    translationKey: 'P93NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P94',
    idx: 'P94',
    translationKey: 'P94NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P95',
    idx: 'P95',
    translationKey: 'P95NSPParamDesc',
    type: MachineType.NovaNsp,
    version: MachineVersion.Nsp161CMin,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
];

const Mc14evoPHRxCacTcParameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    version: MachineVersion.Mc14evoPHRxCacTc,
  })),
];

const Mc14evo_4CanaliTcParameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    version: MachineVersion.Mc14evo_4CanaliTc,
  })),
];

const Mco14_3SezCl2Parameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    type: MachineType.Mco14Evo,
    version: MachineVersion.Mco14_3SezCl2,
  })),
];

const Mco14_5SezCl2ClTClCParameters = [
  ...Mc14evoPHRxCle12TcParameters.map((el) => ({
    ...el,
    type: MachineType.Mco14Evo,
    version: MachineVersion.Mco14_5SezCl2ClTClC,
  })),
];

const NovaNsp161MaParameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp161Ma,
  })),
];

const NovaNsp162PHRxParameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp162PHRx,
  })),
];

const NovaNsp163ACle11_12Parameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp163ACle11_12,
  })),
];

const NovaNsp163BCle16Parameters = [
  ...NovaNsp161CMinParameters.map((el) => ({
    ...el,
    version: MachineVersion.Nsp163BCle16,
  })),
];

const EF315PHRxTcParameters: Parameter[] = [
  {
    name: 'P001',
    idx: 'P001',
    translationKey: 'P01EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P002',
    idx: 'P002',
    translationKey: 'P02EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P003',
    idx: 'P003',
    translationKey: 'P03EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P004',
    idx: 'P004',
    translationKey: 'P04EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P005',
    idx: 'P005',
    translationKey: 'P05EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P006',
    idx: 'P006',
    translationKey: 'P06EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P007',
    idx: 'P007',
    translationKey: 'P07EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P008',
    idx: 'P008',
    translationKey: 'P08EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P009',
    idx: 'P009',
    translationKey: 'P09EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P010',
    idx: 'P010',
    translationKey: 'P10EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P011',
    idx: 'P011',
    translationKey: 'P11EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P012',
    idx: 'P012',
    translationKey: 'P12EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
    isFavorite: true,
  },
  {
    name: 'P013',
    idx: 'P013',
    translationKey: 'P13EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P014',
    idx: 'P014',
    translationKey: 'P14EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P015',
    idx: 'P015',
    translationKey: 'P15EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P016',
    idx: 'P016',
    translationKey: 'P16EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P017',
    idx: 'P017',
    translationKey: 'P17EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P018',
    idx: 'P018',
    translationKey: 'P18EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P019',
    idx: 'P019',
    translationKey: 'P19EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P020',
    idx: 'P020',
    translationKey: 'P20EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P021',
    idx: 'P021',
    translationKey: 'P21EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P022',
    idx: 'P022',
    translationKey: 'P22EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P023',
    idx: 'P023',
    translationKey: 'P23EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P024',
    idx: 'P024',
    translationKey: 'P24EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P025',
    idx: 'P025',
    translationKey: 'P25EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P026',
    idx: 'P026',
    translationKey: 'P26EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P027',
    idx: 'P027',
    translationKey: 'P27EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P028',
    idx: 'P028',
    translationKey: 'P28EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P029',
    idx: 'P029',
    translationKey: 'P29EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P030',
    idx: 'P030',
    translationKey: 'P30EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P031',
    idx: 'P031',
    translationKey: 'P31EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P032',
    idx: 'P032',
    translationKey: 'P32EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P033',
    idx: 'P033',
    translationKey: 'P33EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
  {
    name: 'P034',
    idx: 'P034',
    translationKey: 'P34EFParamDesc',
    type: MachineType.Ef315,
    version: MachineVersion.PHRxTc,
    max: 65535,
    min: -65535,
    hideMinMax: true,
  },
];

const EF315PHClTcParameters = [
  ...EF315PHRxTcParameters.map((el) => ({
    ...el,
    version: MachineVersion.PHClTc,
  })),
];

const EF315PHCacTcParameters = [
  ...EF315PHRxTcParameters.map((el) => ({
    ...el,
    version: MachineVersion.PHCacTc,
  })),
];

const allParameters: Parameter[] = cycleauFirstParameters
  .concat(cycleauProParameters)
  .concat(Mc14evoPHRxCle12TcParameters)
  .concat(Mc14evoPHRxCacTcParameters)
  .concat(Mc14evo_4CanaliTcParameters)
  .concat(Mco14_3SezCl2Parameters)
  .concat(Mco14_5SezCl2ClTClCParameters)
  .concat(NovaNsp161CMinParameters)
  .concat(NovaNsp161MaParameters)
  .concat(NovaNsp162PHRxParameters)
  .concat(NovaNsp163ACle11_12Parameters)
  .concat(NovaNsp163BCle16Parameters)
  .concat(EF315PHRxTcParameters)
  .concat(EF315PHClTcParameters)
  .concat(EF315PHCacTcParameters);

const parseAllParametersDesc = (parameters: Parameter[], module: Maybe<Module>): Parameter[] => {
  if (!module) {
    return parameters;
  }
  const moduleParameters = module?.Parameter?.parameters as ParametersType;
  const output = clone(parameters);
  switch (module.type) {
    case MachineType.Mc14Evo:
    case MachineType.Mco14Evo: {
      const p03Idx = output.findIndex((el) => el.idx === 'P03');
      const p04Idx = output.findIndex((el) => el.idx === 'P04');
      const p11Idx = output.findIndex((el) => el.idx === 'P11');
      const p12Idx = output.findIndex((el) => el.idx === 'P12');
      const p27Idx = output.findIndex((el) => el.idx === 'P27');
      const p28Idx = output.findIndex((el) => el.idx === 'P28');
      if (isNotNullAndNotUndefined(moduleParameters?.P02) && p03Idx >= 0 && p04Idx >= 0) {
        if (
          (moduleParameters!.P02! >= 1 && moduleParameters!.P02! <= 4) ||
          (moduleParameters!.P02! >= 7 && moduleParameters!.P02! <= 8)
        ) {
          output[p03Idx].isFavorite = true;
          output[p04Idx].isFavorite = true;
          output[p03Idx].translationKey = 'P03MC14ParamDescOption1';
          output[p04Idx].translationKey = 'P04MC14ParamDescOption1';
        } else if (moduleParameters!.P02! === 5 || moduleParameters!.P02! === 6) {
          output[p03Idx].translationKey = 'P03MC14ParamDescOption2';
          output[p04Idx].translationKey = 'P04MC14ParamDescOption2';
        } else if (moduleParameters!.P02! === 9) {
          output[p03Idx].translationKey = 'P03MC14ParamDescOption3';
          output[p04Idx].translationKey = 'P04MC14ParamDescOption3';
        }
      }
      if (isNotNullAndNotUndefined(moduleParameters?.P10) && p11Idx >= 0 && p12Idx >= 0) {
        if (
          (moduleParameters!.P10! >= 1 && moduleParameters!.P10! <= 4) ||
          (moduleParameters!.P10! >= 7 && moduleParameters!.P10! <= 8)
        ) {
          output[p11Idx].isFavorite = true;
          output[p12Idx].isFavorite = true;
          output[p11Idx].translationKey = 'P11MC14ParamDescOption1';
          output[p12Idx].translationKey = 'P12MC14ParamDescOption1';
        } else if (moduleParameters!.P10! === 5 || moduleParameters!.P10! === 6) {
          output[p11Idx].translationKey = 'P11MC14ParamDescOption2';
          output[p12Idx].translationKey = 'P12MC14ParamDescOption2';
        } else if (moduleParameters!.P10! === 9) {
          output[p11Idx].translationKey = 'P11MC14ParamDescOption3';
          output[p12Idx].translationKey = 'P12MC14ParamDescOption3';
        }
      }
      if (isNotNullAndNotUndefined(moduleParameters?.P26) && p27Idx >= 0 && p28Idx >= 0) {
        if (
          (moduleParameters!.P26! >= 1 && moduleParameters!.P26! <= 4) ||
          (moduleParameters!.P26! >= 7 && moduleParameters!.P26! <= 8)
        ) {
          output[p27Idx].isFavorite = true;
          output[p28Idx].isFavorite = true;
          output[p27Idx].translationKey = 'P27MC14ParamDescOption1';
          output[p28Idx].translationKey = 'P28MC14ParamDescOption1';
        } else if (moduleParameters!.P26! === 5 || moduleParameters!.P26! === 6) {
          output[p27Idx].translationKey = 'P27MC14ParamDescOption2';
          output[p28Idx].translationKey = 'P28MC14ParamDescOption2';
        } else if (moduleParameters!.P26! === 9) {
          output[p27Idx].translationKey = 'P27MC14ParamDescOption3';
          output[p28Idx].translationKey = 'P28MC14ParamDescOption3';
        }
      }
      break;
    }
    // case MachineType.Ef315: {
    //   const p05Idx = output.findIndex((el) => el.idx === 'P05');
    //   const p12Idx = output.findIndex((el) => el.idx === 'P12');
    //   break;
    // }

    default:
      break;
  }
  return output;
};

export { allParameters, parseAllParametersDesc };
