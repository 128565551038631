import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: Date;
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address. */
  EmailAddress: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export enum Action {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type Alarm = {
  __typename?: 'Alarm';
  alarms?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AlarmHistory = {
  __typename?: 'AlarmHistory';
  alarms?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  moduleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AuthenticateInput = {
  session: Scalars['String'];
};


export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};



export type Device = {
  __typename?: 'Device';
  Company?: Maybe<Company>;
  TechnicianUser?: Maybe<User>;
  User?: Maybe<User>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  modules?: Maybe<Array<Maybe<Module>>>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  samplingTime?: Maybe<Scalars['Int']>;
  technicianUserId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  deviceId: Scalars['String'];
};


export type ExistCompanyInput = {
  companyName: Scalars['String'];
};

export type ExistModuleInput = {
  mac: Scalars['String'];
};

export type ExistUserInput = {
  companyId: Scalars['String'];
  email: Scalars['String'];
};

export type GetSignedStorageLogoUrlInput = {
  companyId: Scalars['String'];
};

export type HandleEf315ParamInput = {
  action: Action;
  moduleId: Scalars['String'];
  parameterName: Scalars['String'];
  parameterValue?: Maybe<Scalars['Float']>;
};

export type HistoryInput = {
  from?: Maybe<Scalars['DateTime']>;
  moduleId: Scalars['String'];
  to?: Maybe<Scalars['DateTime']>;
};

export type Input = {
  __typename?: 'Input';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  inputs?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export enum LanguageCode {
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it'
}

export enum MachineType {
  Cycleau = 'CYCLEAU',
  Ef315 = 'EF315',
  Evolution = 'EVOLUTION',
  Mc14Evo = 'MC14_EVO',
  Mco14Evo = 'MCO14_EVO',
  NovaNsp = 'NovaNSP',
  Unknown = 'UNKNOWN'
}

export enum MachineVersion {
  Ew2Pool = 'EW2POOL',
  First = 'FIRST',
  Mc14evo_4CanaliTc = 'MC14evo_4_canali_TC',
  Mc14evoPHRxCacTc = 'MC14evo_pH_RX_CAC_TC',
  Mc14evoPHRxCle12Tc = 'MC14evo_pH_RX_CLE12_TC',
  Mco14_3SezCl2 = 'MCO14_3_sez_Cl2',
  Mco14_5SezCl2ClTClC = 'MCO14_5_sez_Cl2_ClT_ClC',
  Nsp161CMin = 'NSP161_C_Min',
  Nsp161Ma = 'NSP161_MA',
  Nsp162PHRx = 'NSP162_pH_RX',
  Nsp163ACle11_12 = 'NSP163A_CLE11_12',
  Nsp163BCle16 = 'NSP163B_CLE16',
  Pro = 'PRO',
  Unknown = 'UNKNOWN',
  PHCacTc = 'pH_CAC_TC',
  PHClTc = 'pH_CL_TC',
  PHRxTc = 'pH_RX_TC'
}

export type ManageUserInput = {
  action: Action;
  companyDomain?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  surname?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Measurement = {
  __typename?: 'Measurement';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  measurements?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MeasurementHistory = {
  __typename?: 'MeasurementHistory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  measurements?: Maybe<Scalars['JSON']>;
  moduleId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Module = {
  __typename?: 'Module';
  Alarm?: Maybe<Alarm>;
  Input?: Maybe<Input>;
  Measurement?: Maybe<Measurement>;
  ModuleSettings?: Maybe<ModuleSettings>;
  Output?: Maybe<Output>;
  Parameter?: Maybe<Parameter>;
  alarmId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  env?: Maybe<Scalars['String']>;
  fwVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  inputId?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  lastConnection?: Maybe<Scalars['DateTime']>;
  mac?: Maybe<Scalars['String']>;
  measurementId?: Maybe<Scalars['String']>;
  modelVersion?: Maybe<MachineVersion>;
  moduleSettingsId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notifyDisconnection?: Maybe<Scalars['Boolean']>;
  online?: Maybe<Scalars['Boolean']>;
  outputId?: Maybe<Scalars['String']>;
  parameterId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  tcAppId?: Maybe<Scalars['Int']>;
  tcModuleTypeId?: Maybe<Scalars['Int']>;
  tool?: Maybe<Scalars['String']>;
  type?: Maybe<MachineType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ModuleInput = {
  moduleId: Scalars['String'];
};

export type ModuleSettings = {
  __typename?: 'ModuleSettings';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  settings?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  authenticate?: Maybe<UserWithTokens>;
  changePassword?: Maybe<Scalars['Boolean']>;
  existCompany?: Maybe<Company>;
  existModule?: Maybe<Module>;
  existUser?: Maybe<User>;
  getSignedStorageLogoUrl?: Maybe<Scalars['String']>;
  handleEF315Param?: Maybe<Scalars['Float']>;
  manageUser?: Maybe<User>;
  passwordRecovery?: Maybe<Scalars['Boolean']>;
  resetSettingsDevice?: Maybe<Scalars['Boolean']>;
  signIn?: Maybe<UserWithTokens>;
  signOut?: Maybe<Scalars['Boolean']>;
  updateDevice?: Maybe<Device>;
  updateModuleParameter?: Maybe<Scalars['Boolean']>;
  updateModuleSettings?: Maybe<Module>;
  updateProfile?: Maybe<User>;
  updateProfileAdmin?: Maybe<User>;
};


export type MutationAuthenticateArgs = {
  input?: Maybe<AuthenticateInput>;
};


export type MutationChangePasswordArgs = {
  input?: Maybe<ChangePasswordInput>;
};


export type MutationExistCompanyArgs = {
  input?: Maybe<ExistCompanyInput>;
};


export type MutationExistModuleArgs = {
  input?: Maybe<ExistModuleInput>;
};


export type MutationExistUserArgs = {
  input?: Maybe<ExistUserInput>;
};


export type MutationGetSignedStorageLogoUrlArgs = {
  input?: Maybe<GetSignedStorageLogoUrlInput>;
};


export type MutationHandleEf315ParamArgs = {
  input?: Maybe<HandleEf315ParamInput>;
};


export type MutationManageUserArgs = {
  input?: Maybe<ManageUserInput>;
};


export type MutationPasswordRecoveryArgs = {
  input?: Maybe<PasswordRecoveryInput>;
};


export type MutationResetSettingsDeviceArgs = {
  input?: Maybe<DeviceInput>;
};


export type MutationSignInArgs = {
  input?: Maybe<SignInInput>;
};


export type MutationSignOutArgs = {
  input?: Maybe<SignOutInput>;
};


export type MutationUpdateDeviceArgs = {
  input?: Maybe<UpdateDeviceInput>;
};


export type MutationUpdateModuleParameterArgs = {
  input?: Maybe<UpdateModuleParameterInput>;
};


export type MutationUpdateModuleSettingsArgs = {
  input?: Maybe<UpdateModuleSettingsInput>;
};


export type MutationUpdateProfileArgs = {
  input?: Maybe<UpdateProfileInput>;
};


export type MutationUpdateProfileAdminArgs = {
  input?: Maybe<UpdateProfileAdminInput>;
};

export enum OsType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export type Output = {
  __typename?: 'Output';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  outputs?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  parameters?: Maybe<Scalars['JSON']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PasswordRecoveryInput = {
  companyDomain: Scalars['String'];
  email: Scalars['EmailAddress'];
};

export type Query = {
  __typename?: 'Query';
  alarmsHistory?: Maybe<Array<Maybe<AlarmHistory>>>;
  associableModules?: Maybe<Array<Maybe<Module>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  device?: Maybe<Device>;
  devices?: Maybe<Array<Maybe<Device>>>;
  me?: Maybe<UserWithTokens>;
  measurementsHistory?: Maybe<Array<Maybe<MeasurementHistory>>>;
  user?: Maybe<User>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryAlarmsHistoryArgs = {
  input?: Maybe<HistoryInput>;
};


export type QueryDeviceArgs = {
  input?: Maybe<DeviceInput>;
};


export type QueryMeasurementsHistoryArgs = {
  input?: Maybe<HistoryInput>;
};


export type QueryUserArgs = {
  input?: Maybe<UserInput>;
};

export type SignInInput = {
  companyDomain: Scalars['String'];
  email: Scalars['EmailAddress'];
  password: Scalars['String'];
};

export type SignOutInput = {
  session?: Maybe<Scalars['String']>;
};

export enum UnitOfMeasure {
  C = 'C',
  Ftu = 'FTU',
  Ntu = 'NTU',
  Ph = 'PH',
  Ppm = 'PPM',
  PpmBr2 = 'PPM_BR2',
  PpmCl2 = 'PPM_CL2',
  PpmClc = 'PPM_CLC',
  PpmClt = 'PPM_CLT',
  CMim = 'cMim',
  Cc = 'cc',
  Imp = 'imp',
  M3h = 'm3h',
  MA = 'mA',
  MS = 'mS',
  MV = 'mV',
  MicronS = 'micronS',
  Min = 'min',
  Ml = 'ml',
  Perc = 'perc',
  Sec = 'sec'
}

export type UpdateDeviceInput = {
  action: Action;
  companyId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
  modules?: Maybe<Array<Maybe<UpdateModuleInput>>>;
  name?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  samplingTime?: Maybe<Scalars['Int']>;
  technicianUserId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UpdateModuleInput = {
  modelVersion?: Maybe<MachineVersion>;
  moduleId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  tool?: Maybe<Scalars['String']>;
  type?: Maybe<MachineType>;
};

export type UpdateModuleParameterInput = {
  moduleId: Scalars['String'];
  parameterName: Scalars['String'];
  parameterValue: Scalars['Float'];
};

export type UpdateModuleSettingsInput = {
  moduleId: Scalars['String'];
  settings: Scalars['JSON'];
  settingsIndex: Scalars['Int'];
};

export type UpdateProfileAdminInput = {
  roles?: Maybe<Array<UserRole>>;
  userId: Scalars['String'];
};

export type UpdateProfileInput = {
  languageCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  oldPassword?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};

export enum UploadScope {
  ProfileImage = 'PROFILE_IMAGE'
}

export type User = {
  __typename?: 'User';
  Company?: Maybe<Company>;
  CreatedBy?: Maybe<User>;
  companyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdById?: Maybe<Scalars['String']>;
  disabled?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['EmailAddress']>;
  id?: Maybe<Scalars['ID']>;
  languageCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  notificationStatus?: Maybe<Scalars['Boolean']>;
  place?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<UserRole>>;
  surname?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserInput = {
  userId: Scalars['String'];
};

export enum UserRole {
  Admin = 'ADMIN',
  Supertechnician = 'SUPERTECHNICIAN',
  Technician = 'TECHNICIAN',
  Viewer = 'VIEWER'
}

export type UserWithTokens = {
  __typename?: 'UserWithTokens';
  jwtToken?: Maybe<Scalars['String']>;
  session?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthenticateMutationVariables = Exact<{
  input?: Maybe<AuthenticateInput>;
}>;


export type AuthenticateMutation = (
  { __typename?: 'Mutation' }
  & { authenticate?: Maybe<(
    { __typename?: 'UserWithTokens' }
    & Pick<UserWithTokens, 'jwtToken' | 'session'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  input?: Maybe<ChangePasswordInput>;
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ExistCompanyMutationVariables = Exact<{
  input?: Maybe<ExistCompanyInput>;
}>;


export type ExistCompanyMutation = (
  { __typename?: 'Mutation' }
  & { existCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
  )> }
);

export type ExistModuleMutationVariables = Exact<{
  input?: Maybe<ExistModuleInput>;
}>;


export type ExistModuleMutation = (
  { __typename?: 'Mutation' }
  & { existModule?: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
    & { Alarm?: Maybe<(
      { __typename?: 'Alarm' }
      & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
    )>, Input?: Maybe<(
      { __typename?: 'Input' }
      & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
    )>, Measurement?: Maybe<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
    )>, ModuleSettings?: Maybe<(
      { __typename?: 'ModuleSettings' }
      & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
    )>, Output?: Maybe<(
      { __typename?: 'Output' }
      & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
    )>, Parameter?: Maybe<(
      { __typename?: 'Parameter' }
      & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
    )> }
  )> }
);

export type ExistUserMutationVariables = Exact<{
  input?: Maybe<ExistUserInput>;
}>;


export type ExistUserMutation = (
  { __typename?: 'Mutation' }
  & { existUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type GetSignedStorageLogoUrlMutationVariables = Exact<{
  input?: Maybe<GetSignedStorageLogoUrlInput>;
}>;


export type GetSignedStorageLogoUrlMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'getSignedStorageLogoUrl'>
);

export type HandleEf315ParamMutationVariables = Exact<{
  input?: Maybe<HandleEf315ParamInput>;
}>;


export type HandleEf315ParamMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'handleEF315Param'>
);

export type ManageUserMutationVariables = Exact<{
  input?: Maybe<ManageUserInput>;
}>;


export type ManageUserMutation = (
  { __typename?: 'Mutation' }
  & { manageUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type PasswordRecoveryMutationVariables = Exact<{
  input?: Maybe<PasswordRecoveryInput>;
}>;


export type PasswordRecoveryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'passwordRecovery'>
);

export type ResetSettingsDeviceMutationVariables = Exact<{
  input?: Maybe<DeviceInput>;
}>;


export type ResetSettingsDeviceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetSettingsDevice'>
);

export type SignInMutationVariables = Exact<{
  input?: Maybe<SignInInput>;
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn?: Maybe<(
    { __typename?: 'UserWithTokens' }
    & Pick<UserWithTokens, 'jwtToken' | 'session'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type SignOutMutationVariables = Exact<{
  input?: Maybe<SignOutInput>;
}>;


export type SignOutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'signOut'>
);

export type UpdateDeviceMutationVariables = Exact<{
  input?: Maybe<UpdateDeviceInput>;
}>;


export type UpdateDeviceMutation = (
  { __typename?: 'Mutation' }
  & { updateDevice?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'companyId' | 'createdAt' | 'description' | 'id' | 'name' | 'place' | 'samplingTime' | 'technicianUserId' | 'timezone' | 'updatedAt' | 'userId'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, TechnicianUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, modules?: Maybe<Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
      & { Alarm?: Maybe<(
        { __typename?: 'Alarm' }
        & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
      )>, Input?: Maybe<(
        { __typename?: 'Input' }
        & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
      )>, Measurement?: Maybe<(
        { __typename?: 'Measurement' }
        & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
      )>, ModuleSettings?: Maybe<(
        { __typename?: 'ModuleSettings' }
        & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
      )>, Output?: Maybe<(
        { __typename?: 'Output' }
        & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
      )>, Parameter?: Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type UpdateModuleParameterMutationVariables = Exact<{
  input?: Maybe<UpdateModuleParameterInput>;
}>;


export type UpdateModuleParameterMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateModuleParameter'>
);

export type UpdateModuleSettingsMutationVariables = Exact<{
  input?: Maybe<UpdateModuleSettingsInput>;
}>;


export type UpdateModuleSettingsMutation = (
  { __typename?: 'Mutation' }
  & { updateModuleSettings?: Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
    & { Alarm?: Maybe<(
      { __typename?: 'Alarm' }
      & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
    )>, Input?: Maybe<(
      { __typename?: 'Input' }
      & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
    )>, Measurement?: Maybe<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
    )>, ModuleSettings?: Maybe<(
      { __typename?: 'ModuleSettings' }
      & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
    )>, Output?: Maybe<(
      { __typename?: 'Output' }
      & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
    )>, Parameter?: Maybe<(
      { __typename?: 'Parameter' }
      & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
    )> }
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  input?: Maybe<UpdateProfileInput>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UpdateProfileAdminMutationVariables = Exact<{
  input?: Maybe<UpdateProfileAdminInput>;
}>;


export type UpdateProfileAdminMutation = (
  { __typename?: 'Mutation' }
  & { updateProfileAdmin?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type AlarmsHistoryQueryVariables = Exact<{
  input?: Maybe<HistoryInput>;
}>;


export type AlarmsHistoryQuery = (
  { __typename?: 'Query' }
  & { alarmsHistory?: Maybe<Array<Maybe<(
    { __typename?: 'AlarmHistory' }
    & Pick<AlarmHistory, 'alarms' | 'createdAt' | 'id' | 'moduleId' | 'updatedAt'>
  )>>> }
);

export type AssociableModulesQueryVariables = Exact<{ [key: string]: never; }>;


export type AssociableModulesQuery = (
  { __typename?: 'Query' }
  & { associableModules?: Maybe<Array<Maybe<(
    { __typename?: 'Module' }
    & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
    & { Alarm?: Maybe<(
      { __typename?: 'Alarm' }
      & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
    )>, Input?: Maybe<(
      { __typename?: 'Input' }
      & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
    )>, Measurement?: Maybe<(
      { __typename?: 'Measurement' }
      & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
    )>, ModuleSettings?: Maybe<(
      { __typename?: 'ModuleSettings' }
      & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
    )>, Output?: Maybe<(
      { __typename?: 'Output' }
      & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
    )>, Parameter?: Maybe<(
      { __typename?: 'Parameter' }
      & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
    )> }
  )>>> }
);

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies?: Maybe<Array<Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
  )>>> }
);

export type DeviceQueryVariables = Exact<{
  input?: Maybe<DeviceInput>;
}>;


export type DeviceQuery = (
  { __typename?: 'Query' }
  & { device?: Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'companyId' | 'createdAt' | 'description' | 'id' | 'name' | 'place' | 'samplingTime' | 'technicianUserId' | 'timezone' | 'updatedAt' | 'userId'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, TechnicianUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, modules?: Maybe<Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
      & { Alarm?: Maybe<(
        { __typename?: 'Alarm' }
        & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
      )>, Input?: Maybe<(
        { __typename?: 'Input' }
        & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
      )>, Measurement?: Maybe<(
        { __typename?: 'Measurement' }
        & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
      )>, ModuleSettings?: Maybe<(
        { __typename?: 'ModuleSettings' }
        & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
      )>, Output?: Maybe<(
        { __typename?: 'Output' }
        & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
      )>, Parameter?: Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type DevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type DevicesQuery = (
  { __typename?: 'Query' }
  & { devices?: Maybe<Array<Maybe<(
    { __typename?: 'Device' }
    & Pick<Device, 'companyId' | 'createdAt' | 'description' | 'id' | 'name' | 'place' | 'samplingTime' | 'technicianUserId' | 'timezone' | 'updatedAt' | 'userId'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, TechnicianUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, User?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )>, modules?: Maybe<Array<Maybe<(
      { __typename?: 'Module' }
      & Pick<Module, 'alarmId' | 'createdAt' | 'description' | 'deviceId' | 'env' | 'fwVersion' | 'id' | 'inputId' | 'ipAddress' | 'lastConnection' | 'mac' | 'measurementId' | 'modelVersion' | 'moduleSettingsId' | 'name' | 'notifyDisconnection' | 'online' | 'outputId' | 'parameterId' | 'region' | 'tcAppId' | 'tcModuleTypeId' | 'tool' | 'type' | 'updatedAt'>
      & { Alarm?: Maybe<(
        { __typename?: 'Alarm' }
        & Pick<Alarm, 'alarms' | 'createdAt' | 'id' | 'updatedAt'>
      )>, Input?: Maybe<(
        { __typename?: 'Input' }
        & Pick<Input, 'createdAt' | 'id' | 'inputs' | 'updatedAt'>
      )>, Measurement?: Maybe<(
        { __typename?: 'Measurement' }
        & Pick<Measurement, 'createdAt' | 'id' | 'measurements' | 'updatedAt'>
      )>, ModuleSettings?: Maybe<(
        { __typename?: 'ModuleSettings' }
        & Pick<ModuleSettings, 'createdAt' | 'id' | 'settings' | 'updatedAt'>
      )>, Output?: Maybe<(
        { __typename?: 'Output' }
        & Pick<Output, 'createdAt' | 'id' | 'outputs' | 'updatedAt'>
      )>, Parameter?: Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'createdAt' | 'id' | 'parameters' | 'updatedAt'>
      )> }
    )>>> }
  )>>> }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'UserWithTokens' }
    & Pick<UserWithTokens, 'jwtToken' | 'session'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type MeasurementsHistoryQueryVariables = Exact<{
  input?: Maybe<HistoryInput>;
}>;


export type MeasurementsHistoryQuery = (
  { __typename?: 'Query' }
  & { measurementsHistory?: Maybe<Array<Maybe<(
    { __typename?: 'MeasurementHistory' }
    & Pick<MeasurementHistory, 'createdAt' | 'id' | 'measurements' | 'moduleId' | 'updatedAt'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  input?: Maybe<UserInput>;
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
    & { Company?: Maybe<(
      { __typename?: 'Company' }
      & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
    )>, CreatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      & { Company?: Maybe<(
        { __typename?: 'Company' }
        & Pick<Company, 'createdAt' | 'description' | 'disabled' | 'domain' | 'id' | 'logoUrl' | 'name' | 'updatedAt'>
      )>, CreatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'companyId' | 'createdAt' | 'createdById' | 'disabled' | 'email' | 'id' | 'languageCode' | 'name' | 'notes' | 'notificationStatus' | 'place' | 'roles' | 'surname' | 'updatedAt'>
      )> }
    )> }
  )>>> }
);

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Action: Action;
  Alarm: ResolverTypeWrapper<Alarm>;
  ID: ResolverTypeWrapper<Scalars['ID']>;
  AlarmHistory: ResolverTypeWrapper<AlarmHistory>;
  String: ResolverTypeWrapper<Scalars['String']>;
  AuthenticateInput: AuthenticateInput;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']>;
  ChangePasswordInput: ChangePasswordInput;
  Company: ResolverTypeWrapper<Company>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Date: ResolverTypeWrapper<Scalars['Date']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  Device: ResolverTypeWrapper<Device>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  DeviceInput: DeviceInput;
  EmailAddress: ResolverTypeWrapper<Scalars['EmailAddress']>;
  ExistCompanyInput: ExistCompanyInput;
  ExistModuleInput: ExistModuleInput;
  ExistUserInput: ExistUserInput;
  GetSignedStorageLogoUrlInput: GetSignedStorageLogoUrlInput;
  HandleEF315ParamInput: HandleEf315ParamInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  HistoryInput: HistoryInput;
  Input: ResolverTypeWrapper<Input>;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  LanguageCode: LanguageCode;
  MachineType: MachineType;
  MachineVersion: MachineVersion;
  ManageUserInput: ManageUserInput;
  Measurement: ResolverTypeWrapper<Measurement>;
  MeasurementHistory: ResolverTypeWrapper<MeasurementHistory>;
  Module: ResolverTypeWrapper<Module>;
  ModuleInput: ModuleInput;
  ModuleSettings: ResolverTypeWrapper<ModuleSettings>;
  Mutation: ResolverTypeWrapper<{}>;
  OsType: OsType;
  Output: ResolverTypeWrapper<Output>;
  Parameter: ResolverTypeWrapper<Parameter>;
  PasswordRecoveryInput: PasswordRecoveryInput;
  Query: ResolverTypeWrapper<{}>;
  SignInInput: SignInInput;
  SignOutInput: SignOutInput;
  UnitOfMeasure: UnitOfMeasure;
  UpdateDeviceInput: UpdateDeviceInput;
  UpdateModuleInput: UpdateModuleInput;
  UpdateModuleParameterInput: UpdateModuleParameterInput;
  UpdateModuleSettingsInput: UpdateModuleSettingsInput;
  UpdateProfileAdminInput: UpdateProfileAdminInput;
  UpdateProfileInput: UpdateProfileInput;
  UploadScope: UploadScope;
  User: ResolverTypeWrapper<User>;
  UserInput: UserInput;
  UserRole: UserRole;
  UserWithTokens: ResolverTypeWrapper<UserWithTokens>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Alarm: Alarm;
  ID: Scalars['ID'];
  AlarmHistory: AlarmHistory;
  String: Scalars['String'];
  AuthenticateInput: AuthenticateInput;
  BigInt: Scalars['BigInt'];
  ChangePasswordInput: ChangePasswordInput;
  Company: Company;
  Boolean: Scalars['Boolean'];
  Date: Scalars['Date'];
  DateTime: Scalars['DateTime'];
  Device: Device;
  Int: Scalars['Int'];
  DeviceInput: DeviceInput;
  EmailAddress: Scalars['EmailAddress'];
  ExistCompanyInput: ExistCompanyInput;
  ExistModuleInput: ExistModuleInput;
  ExistUserInput: ExistUserInput;
  GetSignedStorageLogoUrlInput: GetSignedStorageLogoUrlInput;
  HandleEF315ParamInput: HandleEf315ParamInput;
  Float: Scalars['Float'];
  HistoryInput: HistoryInput;
  Input: Input;
  JSON: Scalars['JSON'];
  ManageUserInput: ManageUserInput;
  Measurement: Measurement;
  MeasurementHistory: MeasurementHistory;
  Module: Module;
  ModuleInput: ModuleInput;
  ModuleSettings: ModuleSettings;
  Mutation: {};
  Output: Output;
  Parameter: Parameter;
  PasswordRecoveryInput: PasswordRecoveryInput;
  Query: {};
  SignInInput: SignInInput;
  SignOutInput: SignOutInput;
  UpdateDeviceInput: UpdateDeviceInput;
  UpdateModuleInput: UpdateModuleInput;
  UpdateModuleParameterInput: UpdateModuleParameterInput;
  UpdateModuleSettingsInput: UpdateModuleSettingsInput;
  UpdateProfileAdminInput: UpdateProfileAdminInput;
  UpdateProfileInput: UpdateProfileInput;
  User: User;
  UserInput: UserInput;
  UserWithTokens: UserWithTokens;
}>;

export type AlarmResolvers<ContextType = any, ParentType extends ResolversParentTypes['Alarm'] = ResolversParentTypes['Alarm']> = ResolversObject<{
  alarms?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AlarmHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['AlarmHistory'] = ResolversParentTypes['AlarmHistory']> = ResolversObject<{
  alarms?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  moduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  domain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeviceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device']> = ResolversObject<{
  Company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  TechnicianUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  modules?: Resolver<Maybe<Array<Maybe<ResolversTypes['Module']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  samplingTime?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  technicianUserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface EmailAddressScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['EmailAddress'], any> {
  name: 'EmailAddress';
}

export type InputResolvers<ContextType = any, ParentType extends ResolversParentTypes['Input'] = ResolversParentTypes['Input']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  inputs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type MeasurementResolvers<ContextType = any, ParentType extends ResolversParentTypes['Measurement'] = ResolversParentTypes['Measurement']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  measurements?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MeasurementHistoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['MeasurementHistory'] = ResolversParentTypes['MeasurementHistory']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  measurements?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  moduleId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ModuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Module'] = ResolversParentTypes['Module']> = ResolversObject<{
  Alarm?: Resolver<Maybe<ResolversTypes['Alarm']>, ParentType, ContextType>;
  Input?: Resolver<Maybe<ResolversTypes['Input']>, ParentType, ContextType>;
  Measurement?: Resolver<Maybe<ResolversTypes['Measurement']>, ParentType, ContextType>;
  ModuleSettings?: Resolver<Maybe<ResolversTypes['ModuleSettings']>, ParentType, ContextType>;
  Output?: Resolver<Maybe<ResolversTypes['Output']>, ParentType, ContextType>;
  Parameter?: Resolver<Maybe<ResolversTypes['Parameter']>, ParentType, ContextType>;
  alarmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  env?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fwVersion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  inputId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ipAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastConnection?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  mac?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  measurementId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  modelVersion?: Resolver<Maybe<ResolversTypes['MachineVersion']>, ParentType, ContextType>;
  moduleSettingsId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notifyDisconnection?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  online?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  outputId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parameterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tcAppId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tcModuleTypeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tool?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['MachineType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ModuleSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ModuleSettings'] = ResolversParentTypes['ModuleSettings']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  authenticate?: Resolver<Maybe<ResolversTypes['UserWithTokens']>, ParentType, ContextType, RequireFields<MutationAuthenticateArgs, never>>;
  changePassword?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationChangePasswordArgs, never>>;
  existCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationExistCompanyArgs, never>>;
  existModule?: Resolver<Maybe<ResolversTypes['Module']>, ParentType, ContextType, RequireFields<MutationExistModuleArgs, never>>;
  existUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationExistUserArgs, never>>;
  getSignedStorageLogoUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationGetSignedStorageLogoUrlArgs, never>>;
  handleEF315Param?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, RequireFields<MutationHandleEf315ParamArgs, never>>;
  manageUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationManageUserArgs, never>>;
  passwordRecovery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationPasswordRecoveryArgs, never>>;
  resetSettingsDevice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationResetSettingsDeviceArgs, never>>;
  signIn?: Resolver<Maybe<ResolversTypes['UserWithTokens']>, ParentType, ContextType, RequireFields<MutationSignInArgs, never>>;
  signOut?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSignOutArgs, never>>;
  updateDevice?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<MutationUpdateDeviceArgs, never>>;
  updateModuleParameter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationUpdateModuleParameterArgs, never>>;
  updateModuleSettings?: Resolver<Maybe<ResolversTypes['Module']>, ParentType, ContextType, RequireFields<MutationUpdateModuleSettingsArgs, never>>;
  updateProfile?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateProfileArgs, never>>;
  updateProfileAdmin?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateProfileAdminArgs, never>>;
}>;

export type OutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['Output'] = ResolversParentTypes['Output']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  outputs?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ParameterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Parameter'] = ResolversParentTypes['Parameter']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  parameters?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  alarmsHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['AlarmHistory']>>>, ParentType, ContextType, RequireFields<QueryAlarmsHistoryArgs, never>>;
  associableModules?: Resolver<Maybe<Array<Maybe<ResolversTypes['Module']>>>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  device?: Resolver<Maybe<ResolversTypes['Device']>, ParentType, ContextType, RequireFields<QueryDeviceArgs, never>>;
  devices?: Resolver<Maybe<Array<Maybe<ResolversTypes['Device']>>>, ParentType, ContextType>;
  me?: Resolver<Maybe<ResolversTypes['UserWithTokens']>, ParentType, ContextType>;
  measurementsHistory?: Resolver<Maybe<Array<Maybe<ResolversTypes['MeasurementHistory']>>>, ParentType, ContextType, RequireFields<QueryMeasurementsHistoryArgs, never>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, never>>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  Company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  CreatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdById?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['EmailAddress']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  languageCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notificationStatus?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['UserRole']>>, ParentType, ContextType>;
  surname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserWithTokensResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserWithTokens'] = ResolversParentTypes['UserWithTokens']> = ResolversObject<{
  jwtToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  session?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Alarm?: AlarmResolvers<ContextType>;
  AlarmHistory?: AlarmHistoryResolvers<ContextType>;
  BigInt?: GraphQLScalarType;
  Company?: CompanyResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Device?: DeviceResolvers<ContextType>;
  EmailAddress?: GraphQLScalarType;
  Input?: InputResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Measurement?: MeasurementResolvers<ContextType>;
  MeasurementHistory?: MeasurementHistoryResolvers<ContextType>;
  Module?: ModuleResolvers<ContextType>;
  ModuleSettings?: ModuleSettingsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Output?: OutputResolvers<ContextType>;
  Parameter?: ParameterResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserWithTokens?: UserWithTokensResolvers<ContextType>;
}>;


/**
 * @deprecated
 * Use "Resolvers" root object instead. If you wish to get "IResolvers", add "typesPrefix: I" to your config.
 */
export type IResolvers<ContextType = any> = Resolvers<ContextType>;


export const AuthenticateDocument = gql`
    mutation authenticate($input: AuthenticateInput) {
  authenticate(input: $input) {
    jwtToken
    session
    user {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
  }
}
    `;
export type AuthenticateMutationFn = Apollo.MutationFunction<AuthenticateMutation, AuthenticateMutationVariables>;

/**
 * __useAuthenticateMutation__
 *
 * To run a mutation, you first call `useAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateMutation, { data, loading, error }] = useAuthenticateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<AuthenticateMutation, AuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthenticateMutation, AuthenticateMutationVariables>(AuthenticateDocument, options);
      }
export type AuthenticateMutationHookResult = ReturnType<typeof useAuthenticateMutation>;
export type AuthenticateMutationResult = Apollo.MutationResult<AuthenticateMutation>;
export type AuthenticateMutationOptions = Apollo.BaseMutationOptions<AuthenticateMutation, AuthenticateMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($input: ChangePasswordInput) {
  changePassword(input: $input)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ExistCompanyDocument = gql`
    mutation existCompany($input: ExistCompanyInput) {
  existCompany(input: $input) {
    createdAt
    description
    disabled
    domain
    id
    logoUrl
    name
    updatedAt
  }
}
    `;
export type ExistCompanyMutationFn = Apollo.MutationFunction<ExistCompanyMutation, ExistCompanyMutationVariables>;

/**
 * __useExistCompanyMutation__
 *
 * To run a mutation, you first call `useExistCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExistCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [existCompanyMutation, { data, loading, error }] = useExistCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExistCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ExistCompanyMutation, ExistCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExistCompanyMutation, ExistCompanyMutationVariables>(ExistCompanyDocument, options);
      }
export type ExistCompanyMutationHookResult = ReturnType<typeof useExistCompanyMutation>;
export type ExistCompanyMutationResult = Apollo.MutationResult<ExistCompanyMutation>;
export type ExistCompanyMutationOptions = Apollo.BaseMutationOptions<ExistCompanyMutation, ExistCompanyMutationVariables>;
export const ExistModuleDocument = gql`
    mutation existModule($input: ExistModuleInput) {
  existModule(input: $input) {
    Alarm {
      alarms
      createdAt
      id
      updatedAt
    }
    Input {
      createdAt
      id
      inputs
      updatedAt
    }
    Measurement {
      createdAt
      id
      measurements
      updatedAt
    }
    ModuleSettings {
      createdAt
      id
      settings
      updatedAt
    }
    Output {
      createdAt
      id
      outputs
      updatedAt
    }
    Parameter {
      createdAt
      id
      parameters
      updatedAt
    }
    alarmId
    createdAt
    description
    deviceId
    env
    fwVersion
    id
    inputId
    ipAddress
    lastConnection
    mac
    measurementId
    modelVersion
    moduleSettingsId
    name
    notifyDisconnection
    online
    outputId
    parameterId
    region
    tcAppId
    tcModuleTypeId
    tool
    type
    updatedAt
  }
}
    `;
export type ExistModuleMutationFn = Apollo.MutationFunction<ExistModuleMutation, ExistModuleMutationVariables>;

/**
 * __useExistModuleMutation__
 *
 * To run a mutation, you first call `useExistModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExistModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [existModuleMutation, { data, loading, error }] = useExistModuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExistModuleMutation(baseOptions?: Apollo.MutationHookOptions<ExistModuleMutation, ExistModuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExistModuleMutation, ExistModuleMutationVariables>(ExistModuleDocument, options);
      }
export type ExistModuleMutationHookResult = ReturnType<typeof useExistModuleMutation>;
export type ExistModuleMutationResult = Apollo.MutationResult<ExistModuleMutation>;
export type ExistModuleMutationOptions = Apollo.BaseMutationOptions<ExistModuleMutation, ExistModuleMutationVariables>;
export const ExistUserDocument = gql`
    mutation existUser($input: ExistUserInput) {
  existUser(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export type ExistUserMutationFn = Apollo.MutationFunction<ExistUserMutation, ExistUserMutationVariables>;

/**
 * __useExistUserMutation__
 *
 * To run a mutation, you first call `useExistUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExistUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [existUserMutation, { data, loading, error }] = useExistUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExistUserMutation(baseOptions?: Apollo.MutationHookOptions<ExistUserMutation, ExistUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExistUserMutation, ExistUserMutationVariables>(ExistUserDocument, options);
      }
export type ExistUserMutationHookResult = ReturnType<typeof useExistUserMutation>;
export type ExistUserMutationResult = Apollo.MutationResult<ExistUserMutation>;
export type ExistUserMutationOptions = Apollo.BaseMutationOptions<ExistUserMutation, ExistUserMutationVariables>;
export const GetSignedStorageLogoUrlDocument = gql`
    mutation getSignedStorageLogoUrl($input: GetSignedStorageLogoUrlInput) {
  getSignedStorageLogoUrl(input: $input)
}
    `;
export type GetSignedStorageLogoUrlMutationFn = Apollo.MutationFunction<GetSignedStorageLogoUrlMutation, GetSignedStorageLogoUrlMutationVariables>;

/**
 * __useGetSignedStorageLogoUrlMutation__
 *
 * To run a mutation, you first call `useGetSignedStorageLogoUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetSignedStorageLogoUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getSignedStorageLogoUrlMutation, { data, loading, error }] = useGetSignedStorageLogoUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSignedStorageLogoUrlMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedStorageLogoUrlMutation, GetSignedStorageLogoUrlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedStorageLogoUrlMutation, GetSignedStorageLogoUrlMutationVariables>(GetSignedStorageLogoUrlDocument, options);
      }
export type GetSignedStorageLogoUrlMutationHookResult = ReturnType<typeof useGetSignedStorageLogoUrlMutation>;
export type GetSignedStorageLogoUrlMutationResult = Apollo.MutationResult<GetSignedStorageLogoUrlMutation>;
export type GetSignedStorageLogoUrlMutationOptions = Apollo.BaseMutationOptions<GetSignedStorageLogoUrlMutation, GetSignedStorageLogoUrlMutationVariables>;
export const HandleEf315ParamDocument = gql`
    mutation handleEF315Param($input: HandleEF315ParamInput) {
  handleEF315Param(input: $input)
}
    `;
export type HandleEf315ParamMutationFn = Apollo.MutationFunction<HandleEf315ParamMutation, HandleEf315ParamMutationVariables>;

/**
 * __useHandleEf315ParamMutation__
 *
 * To run a mutation, you first call `useHandleEf315ParamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleEf315ParamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleEf315ParamMutation, { data, loading, error }] = useHandleEf315ParamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHandleEf315ParamMutation(baseOptions?: Apollo.MutationHookOptions<HandleEf315ParamMutation, HandleEf315ParamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleEf315ParamMutation, HandleEf315ParamMutationVariables>(HandleEf315ParamDocument, options);
      }
export type HandleEf315ParamMutationHookResult = ReturnType<typeof useHandleEf315ParamMutation>;
export type HandleEf315ParamMutationResult = Apollo.MutationResult<HandleEf315ParamMutation>;
export type HandleEf315ParamMutationOptions = Apollo.BaseMutationOptions<HandleEf315ParamMutation, HandleEf315ParamMutationVariables>;
export const ManageUserDocument = gql`
    mutation manageUser($input: ManageUserInput) {
  manageUser(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export type ManageUserMutationFn = Apollo.MutationFunction<ManageUserMutation, ManageUserMutationVariables>;

/**
 * __useManageUserMutation__
 *
 * To run a mutation, you first call `useManageUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageUserMutation, { data, loading, error }] = useManageUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useManageUserMutation(baseOptions?: Apollo.MutationHookOptions<ManageUserMutation, ManageUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageUserMutation, ManageUserMutationVariables>(ManageUserDocument, options);
      }
export type ManageUserMutationHookResult = ReturnType<typeof useManageUserMutation>;
export type ManageUserMutationResult = Apollo.MutationResult<ManageUserMutation>;
export type ManageUserMutationOptions = Apollo.BaseMutationOptions<ManageUserMutation, ManageUserMutationVariables>;
export const PasswordRecoveryDocument = gql`
    mutation passwordRecovery($input: PasswordRecoveryInput) {
  passwordRecovery(input: $input)
}
    `;
export type PasswordRecoveryMutationFn = Apollo.MutationFunction<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>;

/**
 * __usePasswordRecoveryMutation__
 *
 * To run a mutation, you first call `usePasswordRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordRecoveryMutation, { data, loading, error }] = usePasswordRecoveryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordRecoveryMutation(baseOptions?: Apollo.MutationHookOptions<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>(PasswordRecoveryDocument, options);
      }
export type PasswordRecoveryMutationHookResult = ReturnType<typeof usePasswordRecoveryMutation>;
export type PasswordRecoveryMutationResult = Apollo.MutationResult<PasswordRecoveryMutation>;
export type PasswordRecoveryMutationOptions = Apollo.BaseMutationOptions<PasswordRecoveryMutation, PasswordRecoveryMutationVariables>;
export const ResetSettingsDeviceDocument = gql`
    mutation resetSettingsDevice($input: DeviceInput) {
  resetSettingsDevice(input: $input)
}
    `;
export type ResetSettingsDeviceMutationFn = Apollo.MutationFunction<ResetSettingsDeviceMutation, ResetSettingsDeviceMutationVariables>;

/**
 * __useResetSettingsDeviceMutation__
 *
 * To run a mutation, you first call `useResetSettingsDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetSettingsDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetSettingsDeviceMutation, { data, loading, error }] = useResetSettingsDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetSettingsDeviceMutation(baseOptions?: Apollo.MutationHookOptions<ResetSettingsDeviceMutation, ResetSettingsDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetSettingsDeviceMutation, ResetSettingsDeviceMutationVariables>(ResetSettingsDeviceDocument, options);
      }
export type ResetSettingsDeviceMutationHookResult = ReturnType<typeof useResetSettingsDeviceMutation>;
export type ResetSettingsDeviceMutationResult = Apollo.MutationResult<ResetSettingsDeviceMutation>;
export type ResetSettingsDeviceMutationOptions = Apollo.BaseMutationOptions<ResetSettingsDeviceMutation, ResetSettingsDeviceMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($input: SignInInput) {
  signIn(input: $input) {
    jwtToken
    session
    user {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignOutDocument = gql`
    mutation signOut($input: SignOutInput) {
  signOut(input: $input)
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const UpdateDeviceDocument = gql`
    mutation updateDevice($input: UpdateDeviceInput) {
  updateDevice(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    TechnicianUser {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    User {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    description
    id
    modules {
      Alarm {
        alarms
        createdAt
        id
        updatedAt
      }
      Input {
        createdAt
        id
        inputs
        updatedAt
      }
      Measurement {
        createdAt
        id
        measurements
        updatedAt
      }
      ModuleSettings {
        createdAt
        id
        settings
        updatedAt
      }
      Output {
        createdAt
        id
        outputs
        updatedAt
      }
      Parameter {
        createdAt
        id
        parameters
        updatedAt
      }
      alarmId
      createdAt
      description
      deviceId
      env
      fwVersion
      id
      inputId
      ipAddress
      lastConnection
      mac
      measurementId
      modelVersion
      moduleSettingsId
      name
      notifyDisconnection
      online
      outputId
      parameterId
      region
      tcAppId
      tcModuleTypeId
      tool
      type
      updatedAt
    }
    name
    place
    samplingTime
    technicianUserId
    timezone
    updatedAt
    userId
  }
}
    `;
export type UpdateDeviceMutationFn = Apollo.MutationFunction<UpdateDeviceMutation, UpdateDeviceMutationVariables>;

/**
 * __useUpdateDeviceMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceMutation, { data, loading, error }] = useUpdateDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeviceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDeviceMutation, UpdateDeviceMutationVariables>(UpdateDeviceDocument, options);
      }
export type UpdateDeviceMutationHookResult = ReturnType<typeof useUpdateDeviceMutation>;
export type UpdateDeviceMutationResult = Apollo.MutationResult<UpdateDeviceMutation>;
export type UpdateDeviceMutationOptions = Apollo.BaseMutationOptions<UpdateDeviceMutation, UpdateDeviceMutationVariables>;
export const UpdateModuleParameterDocument = gql`
    mutation updateModuleParameter($input: UpdateModuleParameterInput) {
  updateModuleParameter(input: $input)
}
    `;
export type UpdateModuleParameterMutationFn = Apollo.MutationFunction<UpdateModuleParameterMutation, UpdateModuleParameterMutationVariables>;

/**
 * __useUpdateModuleParameterMutation__
 *
 * To run a mutation, you first call `useUpdateModuleParameterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleParameterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleParameterMutation, { data, loading, error }] = useUpdateModuleParameterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleParameterMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleParameterMutation, UpdateModuleParameterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleParameterMutation, UpdateModuleParameterMutationVariables>(UpdateModuleParameterDocument, options);
      }
export type UpdateModuleParameterMutationHookResult = ReturnType<typeof useUpdateModuleParameterMutation>;
export type UpdateModuleParameterMutationResult = Apollo.MutationResult<UpdateModuleParameterMutation>;
export type UpdateModuleParameterMutationOptions = Apollo.BaseMutationOptions<UpdateModuleParameterMutation, UpdateModuleParameterMutationVariables>;
export const UpdateModuleSettingsDocument = gql`
    mutation updateModuleSettings($input: UpdateModuleSettingsInput) {
  updateModuleSettings(input: $input) {
    Alarm {
      alarms
      createdAt
      id
      updatedAt
    }
    Input {
      createdAt
      id
      inputs
      updatedAt
    }
    Measurement {
      createdAt
      id
      measurements
      updatedAt
    }
    ModuleSettings {
      createdAt
      id
      settings
      updatedAt
    }
    Output {
      createdAt
      id
      outputs
      updatedAt
    }
    Parameter {
      createdAt
      id
      parameters
      updatedAt
    }
    alarmId
    createdAt
    description
    deviceId
    env
    fwVersion
    id
    inputId
    ipAddress
    lastConnection
    mac
    measurementId
    modelVersion
    moduleSettingsId
    name
    notifyDisconnection
    online
    outputId
    parameterId
    region
    tcAppId
    tcModuleTypeId
    tool
    type
    updatedAt
  }
}
    `;
export type UpdateModuleSettingsMutationFn = Apollo.MutationFunction<UpdateModuleSettingsMutation, UpdateModuleSettingsMutationVariables>;

/**
 * __useUpdateModuleSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateModuleSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleSettingsMutation, { data, loading, error }] = useUpdateModuleSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateModuleSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModuleSettingsMutation, UpdateModuleSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModuleSettingsMutation, UpdateModuleSettingsMutationVariables>(UpdateModuleSettingsDocument, options);
      }
export type UpdateModuleSettingsMutationHookResult = ReturnType<typeof useUpdateModuleSettingsMutation>;
export type UpdateModuleSettingsMutationResult = Apollo.MutationResult<UpdateModuleSettingsMutation>;
export type UpdateModuleSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateModuleSettingsMutation, UpdateModuleSettingsMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($input: UpdateProfileInput) {
  updateProfile(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateProfileAdminDocument = gql`
    mutation updateProfileAdmin($input: UpdateProfileAdminInput) {
  updateProfileAdmin(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;
export type UpdateProfileAdminMutationFn = Apollo.MutationFunction<UpdateProfileAdminMutation, UpdateProfileAdminMutationVariables>;

/**
 * __useUpdateProfileAdminMutation__
 *
 * To run a mutation, you first call `useUpdateProfileAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileAdminMutation, { data, loading, error }] = useUpdateProfileAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileAdminMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileAdminMutation, UpdateProfileAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileAdminMutation, UpdateProfileAdminMutationVariables>(UpdateProfileAdminDocument, options);
      }
export type UpdateProfileAdminMutationHookResult = ReturnType<typeof useUpdateProfileAdminMutation>;
export type UpdateProfileAdminMutationResult = Apollo.MutationResult<UpdateProfileAdminMutation>;
export type UpdateProfileAdminMutationOptions = Apollo.BaseMutationOptions<UpdateProfileAdminMutation, UpdateProfileAdminMutationVariables>;
export const AlarmsHistoryDocument = gql`
    query alarmsHistory($input: HistoryInput) {
  alarmsHistory(input: $input) {
    alarms
    createdAt
    id
    moduleId
    updatedAt
  }
}
    `;

/**
 * __useAlarmsHistoryQuery__
 *
 * To run a query within a React component, call `useAlarmsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAlarmsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAlarmsHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAlarmsHistoryQuery(baseOptions?: Apollo.QueryHookOptions<AlarmsHistoryQuery, AlarmsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AlarmsHistoryQuery, AlarmsHistoryQueryVariables>(AlarmsHistoryDocument, options);
      }
export function useAlarmsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AlarmsHistoryQuery, AlarmsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AlarmsHistoryQuery, AlarmsHistoryQueryVariables>(AlarmsHistoryDocument, options);
        }
export type AlarmsHistoryQueryHookResult = ReturnType<typeof useAlarmsHistoryQuery>;
export type AlarmsHistoryLazyQueryHookResult = ReturnType<typeof useAlarmsHistoryLazyQuery>;
export type AlarmsHistoryQueryResult = Apollo.QueryResult<AlarmsHistoryQuery, AlarmsHistoryQueryVariables>;
export const AssociableModulesDocument = gql`
    query associableModules {
  associableModules {
    Alarm {
      alarms
      createdAt
      id
      updatedAt
    }
    Input {
      createdAt
      id
      inputs
      updatedAt
    }
    Measurement {
      createdAt
      id
      measurements
      updatedAt
    }
    ModuleSettings {
      createdAt
      id
      settings
      updatedAt
    }
    Output {
      createdAt
      id
      outputs
      updatedAt
    }
    Parameter {
      createdAt
      id
      parameters
      updatedAt
    }
    alarmId
    createdAt
    description
    deviceId
    env
    fwVersion
    id
    inputId
    ipAddress
    lastConnection
    mac
    measurementId
    modelVersion
    moduleSettingsId
    name
    notifyDisconnection
    online
    outputId
    parameterId
    region
    tcAppId
    tcModuleTypeId
    tool
    type
    updatedAt
  }
}
    `;

/**
 * __useAssociableModulesQuery__
 *
 * To run a query within a React component, call `useAssociableModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssociableModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssociableModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssociableModulesQuery(baseOptions?: Apollo.QueryHookOptions<AssociableModulesQuery, AssociableModulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AssociableModulesQuery, AssociableModulesQueryVariables>(AssociableModulesDocument, options);
      }
export function useAssociableModulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AssociableModulesQuery, AssociableModulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AssociableModulesQuery, AssociableModulesQueryVariables>(AssociableModulesDocument, options);
        }
export type AssociableModulesQueryHookResult = ReturnType<typeof useAssociableModulesQuery>;
export type AssociableModulesLazyQueryHookResult = ReturnType<typeof useAssociableModulesLazyQuery>;
export type AssociableModulesQueryResult = Apollo.QueryResult<AssociableModulesQuery, AssociableModulesQueryVariables>;
export const CompaniesDocument = gql`
    query companies {
  companies {
    createdAt
    description
    disabled
    domain
    id
    logoUrl
    name
    updatedAt
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const DeviceDocument = gql`
    query device($input: DeviceInput) {
  device(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    TechnicianUser {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    User {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    description
    id
    modules {
      Alarm {
        alarms
        createdAt
        id
        updatedAt
      }
      Input {
        createdAt
        id
        inputs
        updatedAt
      }
      Measurement {
        createdAt
        id
        measurements
        updatedAt
      }
      ModuleSettings {
        createdAt
        id
        settings
        updatedAt
      }
      Output {
        createdAt
        id
        outputs
        updatedAt
      }
      Parameter {
        createdAt
        id
        parameters
        updatedAt
      }
      alarmId
      createdAt
      description
      deviceId
      env
      fwVersion
      id
      inputId
      ipAddress
      lastConnection
      mac
      measurementId
      modelVersion
      moduleSettingsId
      name
      notifyDisconnection
      online
      outputId
      parameterId
      region
      tcAppId
      tcModuleTypeId
      tool
      type
      updatedAt
    }
    name
    place
    samplingTime
    technicianUserId
    timezone
    updatedAt
    userId
  }
}
    `;

/**
 * __useDeviceQuery__
 *
 * To run a query within a React component, call `useDeviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeviceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeviceQuery(baseOptions?: Apollo.QueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
      }
export function useDeviceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeviceQuery, DeviceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeviceQuery, DeviceQueryVariables>(DeviceDocument, options);
        }
export type DeviceQueryHookResult = ReturnType<typeof useDeviceQuery>;
export type DeviceLazyQueryHookResult = ReturnType<typeof useDeviceLazyQuery>;
export type DeviceQueryResult = Apollo.QueryResult<DeviceQuery, DeviceQueryVariables>;
export const DevicesDocument = gql`
    query devices {
  devices {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    TechnicianUser {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    User {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    description
    id
    modules {
      Alarm {
        alarms
        createdAt
        id
        updatedAt
      }
      Input {
        createdAt
        id
        inputs
        updatedAt
      }
      Measurement {
        createdAt
        id
        measurements
        updatedAt
      }
      ModuleSettings {
        createdAt
        id
        settings
        updatedAt
      }
      Output {
        createdAt
        id
        outputs
        updatedAt
      }
      Parameter {
        createdAt
        id
        parameters
        updatedAt
      }
      alarmId
      createdAt
      description
      deviceId
      env
      fwVersion
      id
      inputId
      ipAddress
      lastConnection
      mac
      measurementId
      modelVersion
      moduleSettingsId
      name
      notifyDisconnection
      online
      outputId
      parameterId
      region
      tcAppId
      tcModuleTypeId
      tool
      type
      updatedAt
    }
    name
    place
    samplingTime
    technicianUserId
    timezone
    updatedAt
    userId
  }
}
    `;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesQuery(baseOptions?: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
      }
export function useDevicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(DevicesDocument, options);
        }
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<DevicesQuery, DevicesQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    jwtToken
    session
    user {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MeasurementsHistoryDocument = gql`
    query measurementsHistory($input: HistoryInput) {
  measurementsHistory(input: $input) {
    createdAt
    id
    measurements
    moduleId
    updatedAt
  }
}
    `;

/**
 * __useMeasurementsHistoryQuery__
 *
 * To run a query within a React component, call `useMeasurementsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeasurementsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeasurementsHistoryQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMeasurementsHistoryQuery(baseOptions?: Apollo.QueryHookOptions<MeasurementsHistoryQuery, MeasurementsHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeasurementsHistoryQuery, MeasurementsHistoryQueryVariables>(MeasurementsHistoryDocument, options);
      }
export function useMeasurementsHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeasurementsHistoryQuery, MeasurementsHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeasurementsHistoryQuery, MeasurementsHistoryQueryVariables>(MeasurementsHistoryDocument, options);
        }
export type MeasurementsHistoryQueryHookResult = ReturnType<typeof useMeasurementsHistoryQuery>;
export type MeasurementsHistoryLazyQueryHookResult = ReturnType<typeof useMeasurementsHistoryLazyQuery>;
export type MeasurementsHistoryQueryResult = Apollo.QueryResult<MeasurementsHistoryQuery, MeasurementsHistoryQueryVariables>;
export const UserDocument = gql`
    query user($input: UserInput) {
  user(input: $input) {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    Company {
      createdAt
      description
      disabled
      domain
      id
      logoUrl
      name
      updatedAt
    }
    CreatedBy {
      Company {
        createdAt
        description
        disabled
        domain
        id
        logoUrl
        name
        updatedAt
      }
      CreatedBy {
        companyId
        createdAt
        createdById
        disabled
        email
        id
        languageCode
        name
        notes
        notificationStatus
        place
        roles
        surname
        updatedAt
      }
      companyId
      createdAt
      createdById
      disabled
      email
      id
      languageCode
      name
      notes
      notificationStatus
      place
      roles
      surname
      updatedAt
    }
    companyId
    createdAt
    createdById
    disabled
    email
    id
    languageCode
    name
    notes
    notificationStatus
    place
    roles
    surname
    updatedAt
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;